import { ReactElement } from 'react';

import Richtext from '../../UI/Richtext';
import CTA from '../../UI/Cta';
import { IComponentHeroFadingImageFields } from 'types/contentful';

export default function HeroFadingImage({
  heading,
  body,
  positionRight,
  image,
  cta,
  mobileImage,
}: IComponentHeroFadingImageFields): ReactElement {
  const desktopSrc = image.fields.file.url;
  const mobileSrc = mobileImage?.fields.file.url || desktopSrc;

  return (
    <div className="relative md:h-banner-mobile">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${desktopSrc})`,
        }}
      />
      <div
        className="absolute inset-0 bg-cover bg-center md:hidden"
        style={{
          backgroundImage: `url(${mobileSrc})`,
        }}
      />
      <div
        className={`${
          positionRight ? '' : 'ml-auto'
        } md:w-1/2 h-full relative md:flex md:justify-center z-10`}
      >
        <div
          className={`md:w-3/4 h-full py-8 md:py-0 px-8 md:px-0 flex flex-col justify-center ${
            positionRight ? 'ml-auto' : 'text-right mr-auto'
          } z-20`}
        >
          <div className={`${positionRight ? '' : 'ml-auto'} w-3/5 md:w-full`}>
            <Richtext
              document={heading}
              classNames={`custom-richtext text-wrap text-primary-actual
              `}
            />
          </div>
          <div className={`${positionRight ? 'pr-4' : 'ml-auto'} w-3/5 md:w-full`}>
            {body && (
              <Richtext
                document={body}
                classNames="leading-relaxed text-primary-actual text-lg md:text-2xl bold-blue"
              />
            )}
          </div>
          <div
            className={`${
              positionRight ? 'md:mx-0' : 'md:mr-0'
            }  md:w-full mt-8 max-w-600 mx-auto md:w-3/4`}
          >
            <div className={` ${positionRight ? '' : 'flex justify-end'}`}>
              {cta && <CTA {...cta.fields} classNames="text-primary-actual button-text" />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
